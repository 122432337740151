import React, { useContext } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useGetPRV2PriceData from 'hooks/useGetPRV2PriceData'
import useGetPRVGPriceData from 'hooks/useGetPRVGPriceData'

import { injected, bsc, walletconnect } from 'connectors'
import links from './config'

const Menu: React.FC = props => {
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  // const cakePriceUsd = useGetPriceData()
  const prv2Price = useGetPRV2PriceData()
  const prvgPrice = useGetPRVGPriceData()


  return (
    <UikitMenu
      links={links}
      priceLink="https://exchange.privacyswap.finance/#/swap?theme=dark&use=v2&inputCurrency=0x7762A14082Ab475C06D3868B385E46aE27017231"
      account={account as string}
      login={(connectorId: ConnectorId) => {
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        if (connectorId === 'bsc') {
          return activate(bsc)
        }

        return activate(injected)
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      // cakePriceUsd={cakePriceUsd}
      prv2Price={prv2Price}
      prvgPrice={prvgPrice}

      {...props}
    />
  )
}

export default Menu
