import React from 'react'
import useI18n from 'hooks/useI18n'

export interface TranslatedTextProps {
  translationId: number
  children: string
  // color:string
}

const TranslatedText = ({ translationId, children, }: TranslatedTextProps) => {
  const TranslateString = useI18n()
  return <div >{TranslateString(translationId, children)}</div>
}

export default TranslatedText
