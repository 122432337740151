const priceContracts: {cakeAddress: string, busdAddress: string, lpAddress:string} = {
  cakeAddress: '0x7762A14082Ab475C06D3868B385E46aE27017231',
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0xea275c14414de38f5c19c1e3559af032cc1facf4'
}


export const pricePRV2Contracts: {cakeAddress: string, busdAddress: string, lpAddress:string} = {
  cakeAddress: '0xee10936e1f6b49945b17efeb106cc6992fe5ae47',
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0xa53934f143b8B8Aa8839E6DC01b56041d97EcEBA'
}



export const pricePRVGContracts: {cakeAddress: string, busdAddress: string, lpAddress:string} = {
  cakeAddress: '0x774ad968f8f3801739bcbc970f833c664d28511d',
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f'
}
export default priceContracts