import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://app.privacyswap.finance'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },

  {
    label: 'LP Creator',
    icon: 'NftIcon',
    href: 'https://app.privacyswap.finance/lpCreator',

  },
  // {
  //   label: 'PrivacyPools (OLD)',
  //   icon: 'PoolIcon',
  //   href: 'https://old.privacyswap.finance/pools',
  // },

  {
    label: 'Referral',
    icon: 'FarmIcon',
    href: 'https://app.privacyswap.finance/referrals',
  },

  // {
  //   label: 'PrivacyFarms (OLD)',
  //   icon: 'FarmIcon',
  //   href: 'https://old.privacyswap.finance/farms'
  // },
  // {
  //   label: 'PrivacyPools (OLD)',
  //   icon: 'PoolIcon',
  //   href: 'https://old.privacyswap.finance/pools'
  // },


  {
    label: 'PRV Convertor ',
    icon: 'LayerIcon',
    href: 'https://app.privacyswap.finance/converter',
  },
  {
    label: 'PrivacyFarms',
    icon: 'FarmIcon',
    href: 'https://app.privacyswap.finance/farms'
  },
  {
    label: 'PrivacyPools',
    icon: 'PoolIcon',
    href: 'https://app.privacyswap.finance/pools'
  },


  {
    label: 'PrivacyVaults',
    icon: 'LayerIcon',
    href: 'https://vaults.privacyswap.finance/'
  },
  

  {
    label: 'Privacy Card',
    icon: 'TicketIcon',
    href: 'https://cards.privacyswap.finance'
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://www.goosedefi.com/lottery'
  // },
  {
    label: 'Price Charts',
    icon: 'InfoIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Dex Guru',
        href: 'https://dex.guru/token/0x7762A14082Ab475C06D3868B385E46aE27017231-bsc',
      },
      {
        label: 'Poo Coin',
        href: 'https://poocoin.app/tokens/0x7762A14082Ab475C06D3868B385E46aE27017231',
      },
      {
        label: 'Bogged Finance',
        href: 'https://charts.bogged.finance/?token=0x7762A14082Ab475C06D3868B385E46aE27017231',
      },
      {
        label: 'Dex Tools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0xcd18e1151a4831e5ebcecc611369123012674253',
      },
    ],
  },
  

  {
    label: 'Audit',
    icon: 'AuditIcon',
    href: 'https://github.com/PrivacySwap/Smart-Contract-Audits/blob/main/PrivacySwap.pdf'
  },


  {
    label: 'How-To',
    icon: 'LanguageIcon',
    href: 'https://docs.privacyswap.finance'
  },


  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: "Github",
        href: "https://github.com/privacyswap/",
      },
      {
        label: "Docs",
        href: "https://docs.privacyswap.finance/",
      },
     
    ],
  },
]

export default config
